<template lang="">
  <div class="tw-text-center tw-mb-5">
    <h1 class="tw-text-3xl sm:tw-text-4xl tw-font-bold sm:tw-block tw-mb-1">
      Data Salon
    </h1>
    <p class="tw-text-lg tw-text-gray-400">Informasi umum terkait salon</p>
  </div>
  <FormKit
    name="salon_name"
    label="Nama Salon"
    v-model="name"
    placeholder="Masukkan nama salon"
    validation="required"
    validation-visibility="dirty"
    :classes="defaultFormikClass"
  />
  <FormKit
    name="description"
    type="textarea"
    v-model="description"
    label="Deskripsi"
    placeholder="Masukkan deskripsi salon"
    validation="required"
    validation-visibility="dirty"
    :classes="defaultFormikClass"
  />
  <div class="">
    <label for="images" class="tw-text-lg tw-font-bold"> Foto Salon </label>
    <UploadMedia @media="uploadImage" />
  </div>
  <div class="tw-flex tw-items-center tw-justify-between">
    <Button
      label="Selanjutnya"
      iconPos="right"
      icon="pi pi-arrow-right"
      class="px-6 p-button-info tw-mt-5"
      :disabled="!name || !description || !media.length"
      @click="
        name &&
          description &&
          media.length > 0 &&
          $emit('next-page', { pageIndex: 0, formData: { media } })
      "
    />
  </div>
</template>
<script setup>
import { defaultFormikClass } from "@/constants/formik";
import UploadMedia from "@/components/uploader/UploadMedia.vue";
import { ref } from "vue";

const media = ref(null);
const mediaSelected = ref(false);

const name = ref("");
const description = ref("");

const uploadImage = (newMedia) => {
  mediaSelected.value = false;
  media.value = newMedia;
  if (newMedia.length > 0) mediaSelected.value = true;
};
</script>
